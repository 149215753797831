import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Repatriace, návrat původních druhů a introdukce druhů lovných" />
    <h1>Repatriace, návrat původních druhů a introdukce druhů lovných</h1>
    <p>Na území České republiky došlo a dochází k&nbsp;výrazným změnám v&nbsp;druhovém složení, zde jsou popsány některé významné příklady introdukce obratlovců, dále pak repatriace a přirozeného návratu původních druhů, především pak velkých šelem. Změny v&nbsp;druhovém složení, včetně stručných definic pojmů, jsou obecněji rozepsány v&nbsp;předchozí kapitole.</p>
    <hr />
    <h2>Introdukovaná pěstovaná lovná zvířata</h2>
    <p>Mezi introdukovaná pěstovaná lovná zvířata patří na našem území především mnoho druhů ryb, například tolstolobik, amur, pstruh duhový a jiné druhy, které jsou sice v&nbsp;České republice původní, ale byly vysazeny na jiná stanoviště. Tyto pak mnohdy omezují na daných stanovištích původní druhy, dělají jim konkurenci, což může vést v&nbsp;krajním případě až k&nbsp;vymření.</p>
    <p>Dále do introdukovaných druhů spadají někteří savci a ptáci, kupříkladu bažant polní, muflon, daněk, jelen sika a kamzík horský.</p>
    <h3>Tolstolobik bílý</h3>
    <p><strong>Tolstolobik bílý</strong> je býložravá obrovská ryby vážící až 40 kg, pochází z&nbsp;povodí Amuru. Řeky jsou pro něj v&nbsp;letním období příliš studené, a tak se zde nedokáže přirozeně rozmnožit. V&nbsp;ČR je od roku 1964 do Novomlýnských nádrží, kde měl likvidovat fytomasu – tento druh obstojně zabraňuje zarůstání nádrží.</p>
    <h3>Pstruh duhový</h3>
    <p><strong>Pstruh duhový</strong> je oproti našim původním druhům celý tečkovaný, samci mají po boku růžovou středovou linii. Byl k&nbsp;nám dovezen v&nbsp;roce 1888 z&nbsp;USA (úmoří Pacifiku), snese teplejší a znečištěnější vody, zároveň rychle roste. Je tak oblíbený mezi rybáři a byl zavlečen do celého světa, vytlačuje našeho původního pstruha.</p>
    <h3>Muflon</h3>
    <p><strong>Muflon </strong>je neolitická ovce, zhruba 9 000 let př.n.l. byl zavlečen na Korsiku a do Sardinie. Zde zdivočel a v&nbsp;evropských oborách se pak vyskytuje od 18. století. V&nbsp;ČR se objevuje roku 1878 v&nbsp;Hluboké, nyní v&nbsp;ČR zhruba 12 000 ks. Vyskytuje se v&nbsp;listnatých lesích do 600 m n.m., jde o lovnou zvěř (maso a trofeje).</p>
    <h3>Daněk evropský</h3>
    <p><strong>Daněk evropský</strong> je lovná zvěř, která se do českých obor dostala ze Středomoří v&nbsp;16. století. Koncem 19. století je pak vypuštěn z&nbsp;obor, v&nbsp;ČR je nyní zhruba 11 000 ks a pohybuje se v&nbsp;nižších polohách s&nbsp;listnatými lesy. Na stromech páchá menší škody než jelen.</p>
    <h3>Jelen sika</h3>
    <p><strong>Jelen sika </strong>pochází z&nbsp;východní Asie, kolem roku 1900 se dostává do obor v&nbsp;Čechách. V&nbsp;roce 1945 dochází k&nbsp;poškození obor a úniku do přírody, zatím se vyskytuje jen v&nbsp;západních Čechách, kde se pohybuje zhruba 8 000 ks.</p>
    <h3>Kamzík horský</h3>
    <p><strong>Kamzík horský</strong> zalehává na terénních hranách či na vrcholcích kopců. V&nbsp;Evropě jsou dva základní poddruhy – kamzík alpský (Alpy, Tatry) a kamzík karpatský (Karpaty). Na konci 19. století byla do Lužických hor hrabětem Kinským vysazena alpská populace, rovněž byla roku 1911 vysazena v&nbsp;Jeseníkách. Zde se silně namnožila až na 900 ks a došlo tak relativně nedávno k&nbsp;redukci na 130 ks (Pradědská část) a 4 ks v&nbsp;Keprníku. Od úplné likvidace však bylo kvůli peticím upuštěno.</p>
    <h3>Bažant evropský</h3>
    <p><strong>Bažant obecný</strong> je nejstarší introdukované zvíře z&nbsp;výše jmenovaných. Přirozeně se vyskytuje od&nbsp;jihozápadního Kavkazu až po Pacifik, v&nbsp;zemské krajině střední Evropy se vyskytuje od 11. století. Zvíře je v&nbsp;našich podmínkách zvyklé na péči myslivců, bez ní by pravděpodobně nepřežilo. Na jih od Brna jde o hlavní lovnou zvěř, mláďata vyvádí v&nbsp;lesích – tzv. bažantnicích.</p>
    <hr />
    <h2>Repatriace původních druhů</h2>
    <p>Repatriace (tj. reintrodukce) je uvedení původních druhů na stanoviště, kde předtím vymizely. Typickými příklady repatriovaných (reintrodukovaných) druhů v&nbsp;české krajině jsou rys, vlk, bobr, losos, orel mořský a orel skalní.</p>
    <h3>Rys ostrovid</h3>
    <p>Poslední <strong>rys ostrovid</strong> byl v&nbsp;Čechách uloven roku 1835 u Tábora, v&nbsp;Jeseníkách a Beskydách mizí do počátku 20. století. Roku 1970 byl vysazen v&nbsp;Bavorském NP, mezi lety 1982-1989 se za protestů myslivců dostává na CHKO Šumava. Vrcholu dosáhla populace rysa v&nbsp;roce 1998, kdy jich zde bylo 140 ks, a to nejen na Šumavě, ale i v&nbsp;JZ Čechách. Kvůli ilegálnímu lovu a pytlačení však jeho populace klesla asi na polovinu. V&nbsp;letech 1994 až 1995 dochází ke snaze repatriovat rysa do NP Podyjí, ZOO Jihlava poskytla 6 rysů, nicméně ti nebyli zvyklí na lov v&nbsp;přírodě a do 2 let nebyl v&nbsp;Podyjí opět žádný.</p>
    <h3>Bobr evropský</h3>
    <p>K&nbsp;prvnímu vyhubení <strong>bobra evropského</strong> došlo roku 1650 v&nbsp;Čechách, na Třeboňsku kolem roku 1750 a na Moravě u Grygova roku 1730. V&nbsp;roce 1773 však vzniká farma na bobry u Českého Krumlova, bobr se rozšíří až po Prahu, pak je ale díky pytláctví roku 1876 opět vyhuben.</p>
    <p>Od 60. let 20. století na naše území čas od času nějaký zamířil, ale žádný se zde neusadil.&nbsp; V&nbsp;roce 1976 proběhne pokus vysadit bobra <em>kanadského</em> do lužních lesů u Vídně, ten se ale neuchytí. Bobr evropský se pak roku 1977 objevuje u Záhoří na Slovensku.</p>
    <p>V&nbsp;roce 1986 se objevuje na řece Jihlavě, mezi lety 1991 a 1996 je pak 20 ks vysazeno do nově vzniklého CHKO Litovelské Pomoraví a 4 ks do Poodří. V&nbsp;roce 2004 bylo na území ČR asi 400 bobrů evropských, v&nbsp;současnosti dochází opět k&nbsp;jeho omezování. Je totiž poměrně nenáročný a je velkým cestovatelem, relativně rychle překročil rozvodí Vysočiny.</p>
    <h3>Losos obecný</h3>
    <p><strong>Losos obecný</strong> je původní mimo povodí Dunaje. Dříve šlo o běžnou rybu, v&nbsp;1. polovině 19. století však dochází k&nbsp;velkému snížení populace, byly tak zřízeny odchovny na záchranu jeho populace, ty však nejsou úspěšné. K&nbsp;poslednímu velkému tahu lososů dochází na Labi v&nbsp;roce 1927, poslední ránou byla výstavba vodního díla Střekov v&nbsp;roce 1935, která zamezila jeho migraci dál po Labi.</p>
    <p>V&nbsp;letech 1999 a 2000 byl do Kamenice vysazen losos ze švédské řeky Lagan. I přes vybudování rabích přechodů v&nbsp;Německu a zlepšení kvality vody, výsledek není přesvědčivý. Z&nbsp;odlovů v&nbsp;roce 2002 byly zjištěny 3 ks, v&nbsp;roce 2007 7 ks. V&nbsp;roce 2008 byly dokončeny další přechody v&nbsp;soutěsce Kamenice, postaven byl i přechod ve Střekově, jen byl špatně navržen.</p>
    <h3>Orel mořský</h3>
    <p><strong>Orel mořský </strong>je největší dravec na území ČR, rozpětí křídel činí 2,5 metru. V&nbsp;druhé polovině 19. století mizí v&nbsp;Čechách a ve 20. létech 20. století na Moravě. Hnízdí v&nbsp;jezerních a rybničních oblastech.</p>
    <p>Roku 1986 byla na Třeboňsko dovezena mláďata. Nyní se na území ČR vyskytuje zhruba 30 až 35 párů na Českobudějovicku, Jindřichohradecku, Českolipsku a na jižní Moravě. Asi 150 orlů mořských zde zimuje. V&nbsp;poslední době však opět graduje ilegální odlov, v&nbsp;roce 2006 jich například 9 bylo otráveno návnadou v&nbsp;mršině ryby. Hrozí opětovné vyhubení populace.</p>
    <h3>Orel skalní</h3>
    <p><strong>Orel skalní </strong>zde hnízdil do 80. let 19. století, a to v&nbsp;Krkonoších a Beskydech. Nyní hnízdí v&nbsp;Alpách a na naše území zřídka zavítá, v&nbsp;současnosti probíhají pokusy o reintrodukci.</p>
    <hr />
    <h2>Přirozený návrat původních druhů</h2>
    <p>Původní druhy se mohou vrátit na svá stanoviště i přirozeně, migrací, a to po poklesnutí tlaku lovců, počtu predátorů či po zlepšení podmínek prostředí. Příkladem jsou rys a vlk na Moravě, medvěd, bobr na Labi, los, prase divoké a krkavec.</p>
    <h3>Rys ostrovid</h3>
    <p><strong>Rys</strong> byl vybit v&nbsp;Čechách roku 1835 a na Moravě do počátku 20. století. Na Moravu se vrátil ze Slovenska v&nbsp;roce 1946, byly tu však poté vybity nejméně 4 přirozeně vzniklé populace – v&nbsp;Jeseníkách (1950 až 1959 a 1983 až 1989), NP Česko-Saské Švýcarsko (pravděpodobně vybit), Beskydy (1960 až 1969, od roku 1980 opět návrat) a Javořická vrchovina (1980 až 1986). Dnes se však především v&nbsp;Jeseníkách a Beskydech vyskytuje asi 100 ks přirozeně navrátivších rysů.</p>
    <h3>Vlk eurasijský</h3>
    <p><strong>Vlk</strong> byl u nás jako škodná intenzivně loven. Poslední vlk na Šumavě byl uloven roku 1874, v&nbsp;Beskydech roku 1914. V&nbsp;Beskydech se několik kusu vyskytuje od roku 1994, od roky 1996 se též vyskytuje na Šumavě (ale ten byl nejspíše na německé straně vysazen uměle), přirozeně se objevil v&nbsp;nedávné době na Kokořínsku a Broumovsku.</p>
    <h3>Medvěd hnědý</h3>
    <p>Lov <strong>medvěda hnědého (brtníka)</strong> vrcholí v&nbsp;okolí Brna v&nbsp;roce 1570, většina jich je u nás vyhubena 17. a 18. století, poslední ulovený medvěd byl zastřelen na Šumavě-Želnavě v&nbsp;roce 1856, stopy však byly nacházeny až do roku 1875. Na Moravě byl poslední zastřelen roku 1893 v&nbsp;Hukvaldech, zde se ale čas od času nějaký zatoulal ze Slovenska i potom.</p>
    <p>Od 70. let 20. století dochází k&nbsp;ojedinělému návratu medvědů na Moravu. Dnes je v&nbsp;Beskydech až 5 kusů, všechny ze Slovenska. Tam se jim daří, je zde přítomno asi 700 ks. Ojediněle mohou mladí samci migrovat i přes nížiny – Břeclavsko.</p>
    <h3>Los evropský</h3>
    <p><strong>Los</strong> bylo pravděpodobně naše první vylovené zvíře, na našem území mizí koncem 15. století. V&nbsp;roce 1957 přichází z&nbsp;Polska do severních Čech, ale pokračuje dál do Německa. Roku 1979 však již populace losa zůstává a usazuje se v&nbsp;jižních Čechách v&nbsp;okolí Nové Bystřice. Zde jsou vlhké lesy a dochází zde k&nbsp;roznožení losa, dnes je zde přítomno asi 50 ks. Nově se rozšířil i do Lužických hor.</p>
    <h3>Prase divoké</h3>
    <p><strong>Prase divoké</strong> bylo vyhubeno za Josefa II. Vrací se k&nbsp;nám po roce 1945 z&nbsp;poničených obor na východní frontě. V&nbsp;dnešní době došlo k&nbsp;jeho silnému přemnožení a páchá silné škody na lesním porostu.</p>
    <h3>Ostatní</h3>
    <p>Došlo rovněž k&nbsp;přirozenému návratu <strong>bobra evropského</strong> na Labe a <strong>krkavce velkého</strong>, ten byl dříve likvidován jako škodná. Od roku 1868 však u nás vymizel a není jasné proč, jenom jeho lovem to totiž téměř jistě nebylo. Roku 1950 zahnízdil v&nbsp;Bukovských horách na Slovensku, poté zahnízdil v&nbsp;roce 1968 v&nbsp;Beskydech a roku 1978 i v&nbsp;Jeseníkách. Dnes se rozšířil i do západních Čech a opět není jisté, co způsobilo jeho návrat.</p>
    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Repatriace, návrat původních druhů a introdukce druhů lovných</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/prichod-a-vymirani-druhu/"><button className="inv">&larr; Přichod a vymírání druhů</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
